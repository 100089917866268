import React from "react"
import '../styles/main.scss'
import Layout from '../components/Layout'
import { Link, graphql } from 'gatsby'
//import Img from "gatsby-image"
import BackgroundImage from 'gatsby-background-image'

export const query = graphql`
  query bgimage {
  file(relativePath: {eq: "girl.jpg"}) {
    id
    childImageSharp {
      fluid (quality: 95, maxWidth: 1080) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
}  
`
export default function Home({data}) {
  //console.log(data);
  const bgImage = data.file.childImageSharp.fluid;
  return (
    <div>
      <Layout>
        <div className="flex items-center justify-center pa3 bg-lightest-blue navy">
          <svg className="w1" data-icon="info" viewBox="0 0 32 32" >
            <title>info icon</title>
            <path d="M16 0 A16 16 0 0 1 16 32 A16 16 0 0 1 16 0 M19 15 L13 15 L13 26 L19 26 z M16 6 A3 3 0 0 0 16 12 A3 3 0 0 0 16 6"></path>
          </svg>
          <span className="lh-title ml3">Read about our COVID-19 response <Link to='/blog/covid-19'>here.</Link></span>
        </div>

        <article className="dt w-100 bg-black" >
        <BackgroundImage
          Tag="section"
          className='dt w-100'
          fluid={bgImage}
          backgroundColor={`#040e18`}
        >

          <div className="dtc-l dtc-m ph2-m w-70-m pb5-m v-mid white tc ph3 ph4-l w-50-l pb5-l center flex flex-column justify-center items-center vh-75" >
            <div className="center ma3 pa3 bg-black-40 br4 mw-100 mw6-ns mw6-m">
              <h1 className="index-header georgia f2 mb2 mt0 f-subheadline-l f1-m fw6 tc">Dr. Winer<br />Optometry</h1>
              <p className='georgia f5 f4-ns f3-l ma3 pa2 db w-80 center'>With over 30 years of experience, your family eye doctor in Markham.</p>
              <Link className="blue-button br3 f5 no-underline grow dib v-mid white ba ph3 pv2 mb3 mr3" to="/contact">Contact Us</Link>
              <Link className="f6 br3 no-underline grow dib v-mid white ba b--white ph3 pv2 mb3" to="/blog">Read our Blog</Link>
            </div>
          </div>
          <div className="w-50-l w-100 fl-l o-0">div</div>
        </BackgroundImage>
        </article>
      </Layout>
    </div>
    )
}



